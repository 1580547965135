<template>
  <div class="text-sm text-center">
    <p>{{ versus }}</p>
    <div class="flex items-center justify-center gap-2">
      <IcArrow
        :direction="delta > 0 ? 'up' : 'down'"
        size="16"
        :class="textColor"
      />
      <p>{{ delta }}{{ unit }}</p>
    </div>
  </div>
</template>

<script>
import IcArrow from '@/components/icon/ic-arrow.vue'
export default {
  name: 'DeltaArrow',
  components: { IcArrow },
  props: {
    valueA: {
      type: String,
      required: true,
    },
    valueB: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    reversed: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    versus() {
      return `${this.valueA}${this.unit} vs  ${this.valueB}${this.unit}`
    },
    delta() {
      if (parseFloat(this.valueA) === 0 || parseFloat(this.valueB) === 0) return '-'
      return (((parseFloat(this.valueA) - parseFloat(this.valueB)) / parseFloat(this.valueB)) * 100).toFixed(2)
    },
    textColor() {
      if (this.reversed) {
        return this.delta > 0 ? 'text-bb-error' : 'text-success'
      }
      return this.delta > 0 ? 'text-success' : 'text-bb-error'
    },
  },
}
</script>

<style scoped lang="scss"></style>
