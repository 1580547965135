<template>
  <bbBaseCard
    :checkbox="true"
    :curtain="true"
    :is-checked="isChecked"
    @cardSelect="selectSuggestion"
  >
    <template #first>
      <div class="flex flex-col space-y-1">
        <p class="text-bb-text-secondary">Search term</p>
        <new-tooltip :direction="'bottom-start'">
          <div class="flex items-center gap-4">
            <p class="font-bold truncate overflow-auto">
              {{
                keywordSuggestion.suggestion_type === 'pause'
                  ? keywordSuggestion.keyword_text
                  : keywordSuggestion.searchTermView_searchTerm
              }}
            </p>
            <p
              v-if="keywordSuggestion.suggestion_type === 'pause'"
              class="capitalize"
            >
              ({{ keywordSuggestion.match_type.toLowerCase() }})
            </p>
          </div>
          <template #content>
            {{
              keywordSuggestion.suggestion_type === 'pause'
                ? keywordSuggestion.keyword_text
                : keywordSuggestion.searchTermView_searchTerm
            }}
          </template>
        </new-tooltip>
        <chips-collection
          v-if="keywordReasons.length > 0"
          chip-style="text-xs font-bold bg-neutral-0 rounded-sm h-6 px-3 capitalize"
          :chips="keywordReasons"
          :max-chips-to-display="2"
        />
      </div>
    </template>
    <template #content>
      <div class="flex flex-row justify-between w-full space-x-4">
        <div class="card-item-left">
          <p class="text-bb-text-secondary">Add as</p>
          <div class="flex flex-row gap-2 justify-start p1">
            <status :status="keywordSuggestion.suggestion_type" />
          </div>
        </div>
        <div class="card-item-left-big">
          <p class="text-bb-text-secondary">Ad Group</p>
          <div class="flex flex-row gap-2 justify-start p1 max-w-40">
            <p class="truncate">
              {{ keywordSuggestion.adGroup_name }}
            </p>
          </div>
        </div>
        <div class="card-item-right">
          <p class="text-bb-text-secondary">CPA</p>
          <p class="pNumLarge">{{ sanitizeNull(keywordSuggestion.metrics_cpa).toFixed(2) }} {{ currency }}</p>
        </div>
        <div class="card-item-right">
          <p class="text-bb-text-secondary">CPC</p>
          <p class="pNumLarge">{{ sanitizeNull(keywordSuggestion.metrics_cpc).toFixed(2) }} {{ currency }}</p>
        </div>
        <div class="card-item-right">
          <p class="text-bb-text-secondary">Cost</p>
          <p class="pNumLarge">{{ sanitizeNull(keywordSuggestion.metrics_cost).toFixed(2) }} {{ currency }}</p>
        </div>
        <div class="card-item-right">
          <p class="text-bb-text-secondary">Conv.</p>
          <p class="pNumLarge">{{ sanitizeNull(keywordSuggestion.metrics_conversions) }}</p>
        </div>
        <div class="card-item-right">
          <p class="text-bb-text-secondary">Conv. Rate</p>
          <p class="pNumLarge">{{ (sanitizeNull(keywordSuggestion.metrics_cvr) * 100).toFixed(2) }}%</p>
        </div>
        <div class="card-item-right">
          <p
            class="text-bb-text-secondary"
            :class="{ hidden: sanitizeNull(keywordSuggestion.metrics_conversionsValue) === 0 }"
          >
            ROAS
          </p>
          <p
            class="pNumLarge"
            :class="{ hidden: sanitizeNull(keywordSuggestion.metrics_conversionsValue) === 0 }"
          >
            {{
              (keywordSuggestion.metrics_cost
                ? (sanitizeNull(keywordSuggestion.metrics_conversionsValue) / keywordSuggestion.metrics_cost) * 100
                : 0
              ).toFixed(2)
            }}%
          </p>
        </div>
      </div>
    </template>
    <template #expanded-content>
      <div class="grid grid-cols-12 gap-5 p-4">
        <div class="space-y-1 col-span-1 border p-4 rounded">
          <p class="p3 text-bb-text-secondary">Clicks</p>
          <p class="p2 text-bb-text-default font-medium text-center">
            {{ sanitizeNull(keywordSuggestion.metrics_clicks) }}
          </p>
        </div>

        <div class="space-y-1 col-span-1 border p-4 rounded">
          <p class="p3 text-bb-text-secondary">CTR</p>
          <p class="p2 text-bb-text-default font-medium">
            {{ (sanitizeNull(keywordSuggestion.metrics_ctr) * 100).toFixed(2) }}%
          </p>
        </div>

        <div class="space-y-1 col-span-1 border p-4 rounded">
          <p class="p3 text-bb-text-secondary">Intent</p>
          <div class="p2 text-bb-text-default font-medium flex justify-center">
            <IntentType :intent-type="keywordSuggestion.intent || '-'" />
          </div>
        </div>

        <div class="space-y-1 col-span-2 border p-4 rounded">
          <p class="p3 text-bb-text-secondary">Conv. Rate vs Campaign</p>
          <DeltaArrow
            unit="%"
            :value-a="(sanitizeNull(keywordSuggestion.metrics_cvr) * 100).toFixed(2) || 0"
            :value-b="(sanitizeNull(keywordSuggestion.metrics_cvr_campaign) * 100).toFixed(2) || 0"
          />
        </div>

        <div class="space-y-1 col-span-2 border p-4 rounded">
          <p class="p3 text-bb-text-secondary">CTR vs Avg. CTR</p>
          <DeltaArrow
            unit="%"
            :value-a="(sanitizeNull(keywordSuggestion.metrics_ctr) * 100).toFixed(2) || 0"
            :value-b="
              (keywordSuggestion.metrics_impressions_campaign
                ? sanitizeNull(
                    keywordSuggestion.metrics_clicks_campaign / keywordSuggestion.metrics_impressions_campaign,
                  ) * 100
                : 0
              ).toFixed(2)
            "
          />
        </div>

        <div class="space-y-1 col-span-2 border p-4 rounded">
          <p class="p3 text-bb-text-secondary">CPA vs Campaign</p>
          <DeltaArrow
            :unit="` ${currency}`"
            :value-a="sanitizeNull(keywordSuggestion.metrics_cpa).toFixed(2) || 0"
            :value-b="sanitizeNull(keywordSuggestion.metrics_cpa_campaign).toFixed(2) || 0"
          />
        </div>

        <div class="space-y-1 col-span-2 border p-4 rounded">
          <p class="p3 text-bb-text-secondary">CPC vs Campaign</p>
          <DeltaArrow
            reversed
            :unit="` ${currency}`"
            :value-a="sanitizeNull(keywordSuggestion.metrics_cpc).toFixed(2) || 0"
            :value-b="sanitizeNull(keywordSuggestion.metrics_cpc_campaign).toFixed(2) || 0"
          />
        </div>
      </div>
    </template>
  </bbBaseCard>
</template>
<script>
import Status from '@/components/shared/Status'
import DeltaArrow from '@/components/shared/DeltaArrow.vue'
import IntentType from '@/components/shared/IntentType.vue'
import bbBaseCard from '@/components/card/bbBaseCard.vue'
import NewTooltip from '@/components/alert/NewTooltip.vue'
import ChipsCollection from '@/components/shared/ChipsCollection.vue'

const CHIP_STYLE = {
  relevant: {
    background: 'bg-bb-decorative-cyan',
    color: 'text-bb-solid-blue',
  },
  irrelevant: {
    background: 'bg-bb-light-yellow-brown',
    color: 'text-bb-yellow-brown',
  },
  'poor performance': {
    background: 'bg-bb-blush-pink',
    color: 'text-bb-solid-red',
  },
  'strong performance': {
    background: 'bg-bb-light-green',
    color: 'text-bb-solid-green',
  },
  'high impact (cost)': {
    background: 'bg-bb-dull-purple',
    color: 'text-bb-violet',
  },
}

export default {
  name: 'KeywordSuggestions',
  components: {
    bbBaseCard,
    Status,
    DeltaArrow,
    IntentType,
    NewTooltip,
    ChipsCollection,
  },
  props: {
    keywordSuggestion: {
      type: Object,
      required: true,
    },
    selectedKeywords: {
      type: Array,
      default: () => [],
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isChecked: false,
      hoveredCard: null,
    }
  },
  computed: {
    keywordReasons() {
      if (!this.keywordSuggestion.tags.infos) return []
      return this.keywordSuggestion.tags.infos.map(tag => {
        let reasonTag = tag.toLowerCase()

        if (tag.toLowerCase().includes('poor performance') || tag.toLowerCase().includes('strong performance')) {
          reasonTag = tag.toLowerCase().split('(')[0].trim()
        }

        const reason = CHIP_STYLE[reasonTag] || {}

        return {
          label: tag,
          color: reason?.color || '',
          background: reason?.background || '',
        }
      })
    },
  },
  watch: {
    selectedKeywords(newVal) {
      if (newVal.length === 0) {
        this.isChecked = false
      }
    },
  },
  methods: {
    selectSuggestion() {
      this.isChecked = !this.isChecked
      this.$emit('select-keyword')
    },
    sanitizeNull(metric) {
      return metric === null ? 0 : metric
    },
  },
}
</script>

<style scoped>
.card-item-right {
  @apply text-right flex flex-col;
  min-width: 100px;
}

.card-item-left {
  @apply text-left flex flex-col;
  min-width: 100px;
}

.card-item-left-big {
  @apply text-left flex flex-col;
  min-width: 160px;
}
</style>
