<template>
  <div class="p-8">
    <div class="space-y-4">
      <div>
        <h3 class="h3">
          {{ ModalTitle[undoType] }}
        </h3>
      </div>

      <div>
        <p v-if="undoType === UNDO_TYPES.UNDO">
          Are you sure you want to revert all selected changes? The keywords will be re-added to the suggestions list.
          You wont be able to revert this action.
        </p>
        <p v-if="undoType === UNDO_TYPES.UNDO_AND_REJECT">
          Are you sure you want to undo and reject all selected changes? You wont be able to revert this action.
        </p>
        <p v-if="undoType === UNDO_TYPES.UNDO_ALL">
          Are you sure you want to undo all Autopilot changes? You wont be able to revert this action.
        </p>
      </div>

      <div class="flex gap-4 items-start p-4 border-2 border-bb-brand-purple bg-bb-secondary-purple rounded-md">
        <div>
          <ic-info
            size="20"
            class="text-bb-purple-600"
          />
        </div>
        <p>
          The Autopilot and user actions will be undone on <span class="font-bold">all the campaigns</span> and
          <span class="font-bold">ad groups</span> of your site.
        </p>
      </div>
    </div>
    <div class="grid gap-2 w-full pt-6">
      <div class="flex justify-between items-center">
        <MergeButtonRound
          button-type="secondary"
          class="w-30"
          @click="closeModal"
        >
          <p>Cancel</p>
        </MergeButtonRound>

        <MergeButtonRound
          button-type="primary"
          @click="confirmUndoActivity"
        >
          <p
            v-if="undoType === UNDO_TYPES.UNDO"
            class="w-18"
          >
            Undo
          </p>
          <p v-if="undoType === UNDO_TYPES.UNDO_AND_REJECT">Undo & Reject</p>
          <p v-if="undoType === UNDO_TYPES.UNDO_ALL">Yes</p>
        </MergeButtonRound>
      </div>
    </div>
  </div>
</template>

<script>
import MergeButtonRound from '@/components/btn/MergeButtonRound.vue'
import IcInfo from '@/components/icon/ic-info.vue'
import { UNDO_TYPES } from '@/utils/enums'

const ModalTitle = {
  [UNDO_TYPES.UNDO]: 'Undo multiple changes ?',
  [UNDO_TYPES.UNDO_AND_REJECT]: 'Undo and reject multiple changes ?',
  [UNDO_TYPES.UNDO_ALL]: 'Undo multiple Autopilot changes ?',
}

export default {
  name: 'ConfirmUndoModalForm',
  components: { MergeButtonRound, IcInfo },
  props: {
    undoType: {
      type: String,
      default: UNDO_TYPES.UNDO,
    },
  },
  data() {
    return {
      UNDO_TYPES,
      ModalTitle,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close-modal')
    },
    confirmUndoActivity() {
      this.$emit('confirm-undo-activity', this.undoType)
    },
  },
}
</script>

<style scoped lang="scss"></style>
