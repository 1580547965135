<template>
  <tbody>
    <tr v-if="tableRows.length === 0">
      <td colspan="4">
        <div class="mx-auto my-20 flex flex-col items-center justify-center">
          <p class="text-xl my-2">No expressions found...</p>
        </div>
      </td>
    </tr>
    <tr
      v-for="(item, index) in tableRows"
      v-else
      :key="item.id"
      class="whitespace-no-wrap font-normal w-full text-sm"
      :class="[
        hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white',
        index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
      ]"
      @mouseenter="hoveredRow = index"
      @mouseleave="hoveredRow = null"
    >
      <td
        class="px-4 py-2 whitespace-no-wrap sticky left-0 w-50"
        :class="[
          hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white',
          index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
        ]"
      >
        {{ item.expression }}
      </td>
      <td class="px-4">
        <div class="flex items-center justify-end pr-20">
          <check-box
            class="cursor-pointer"
            :class="touchedExpressions.includes(item.id) ? 'pointer-events-none' : ''"
            :input-name="`box-${item.id}`"
            :input-id="item.id"
            :value="item.is_competitor"
            @input="
              val => {
                updateExpression(item.id, val)
              }
            "
          />
        </div>
      </td>
      <td class="px-4 text-right">
        <div class="flex items-center justify-end pr-5">
          <ic-negative
            :size="20"
            class="cursor-pointer"
            :class="
              touchedExpressions.includes(item.id)
                ? 'text-bb-disabled-buttons pointer-events-none'
                : 'text-bb-red-error'
            "
            @click="removeExpression(item.id)"
          />
        </div>
      </td>
    </tr>
    <tr class="bg-neutral-50 h-14 sticky bottom-0">
      <td colspan="4">
        <div class="flex justify-between items-center pl-6 pr-2">
          <div class="flex items-center gap-6 w-full">
            <ic-plus
              :size="24"
              class="text-bb-brand-purple cursor-pointer"
              @click="addExpression"
            />
            <input
              v-if="!isLoading"
              v-model="expressionInput"
              class="bg-transparent w-full border-0 focus:outline-none"
              type="text"
              placeholder="Add expression..."
              @keydown.enter="addExpression"
              @input="
                hasDuplicateKeyword = false
                maximumLengthError = false
                minimumLengthError = false
              "
            />
            <ellipsis-loader
              v-if="isLoading"
              class="text-bb-brand-purple"
            />
          </div>
          <div>
            <p
              v-if="!hasDuplicateKeyword && !maximumLengthError && !minimumLengthError"
              class="text-bb-neutral-gray text-xs cursor-default w-32"
            >
              Press Enter to save
            </p>
            <p
              v-if="hasDuplicateKeyword"
              class="text-bb-red-error text-xs cursor-default w-48"
            >
              This expression already exists!
            </p>
            <p
              v-if="minimumLengthError"
              class="text-bb-red-error text-xs cursor-default w-50"
            >
              Please enter at least 2 characters.
            </p>
            <p
              v-if="maximumLengthError"
              class="text-bb-red-error text-xs cursor-default w-50"
            >
              Please enter at most 22 characters.
            </p>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import CheckBox from '@/components/input/brightbid/CheckBox.vue'
import IcNegative from '@/components/icon/brightbid/ic-negative.vue'
import IcPlus from '@/components/icon/ic-plus.vue'
import EllipsisLoader from '@/components/loader/EllipsisLoader.vue'
export default {
  name: 'ExclusionRows',
  components: { CheckBox, IcNegative, IcPlus, EllipsisLoader },
  props: {
    tableRows: {
      type: Array,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    touchedExpressions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      hoveredRow: null,
      expressionInput: '',
      hasDuplicateKeyword: false,
      maximumLengthError: false,
      minimumLengthError: false,
    }
  },
  methods: {
    addExpression() {
      const expression = this.expressionInput.trim()
      if (expression === '') {
        this.clearInput()
        return
      }

      if (expression.length < 2) {
        this.minimumLengthError = true
        return
      }
      if (expression.length > 22) {
        this.maximumLengthError = true
        return
      }
      this.tableRows.find(item => item.expression === expression)
        ? (this.hasDuplicateKeyword = true)
        : (this.hasDuplicateKeyword = false)
      if (this.hasDuplicateKeyword) return

      this.$emit('add-expression', expression)
      this.clearInput()
    },
    removeExpression(id) {
      if (this.touchedExpressions.includes(id)) return
      this.$emit('remove-expression', id)
    },
    updateExpression(id, isCompetitor) {
      if (this.touchedExpressions.includes(id)) return
      this.$emit('update-expression', id, isCompetitor)
    },
    clearInput() {
      this.expressionInput = ''
      this.hasDuplicateKeyword = false
    },
  },
}
</script>
<style scoped lang="scss"></style>
