<template>
  <div>
    <div>
      <div class="max-w-200 w-full p-6 space-y-3">
        <div class="flex items-center gap-2">
          <ic-block
            :size="20"
            class="text-bb-brand-purple"
          />
          <h6 class="text-base font-bold">Exclusion List</h6>
        </div>
        <p class="text-sm">
          Prevent certain expressions, such as competitor names, from appearing in any of your positive suggestions.
        </p>
      </div>
      <!--TABLE-->
      <div class="w-full mx-6 my-4 max-w-200">
        <div class="border rounded-lg overflow-hidden">
          <div class="max-h-120 overflow-y-auto base-scrollbar">
            <table class="w-full">
              <table-header
                :headers="headers"
                :sorting-order="sortingOrder"
                class="sticky top-0 bg-white z-10"
                @sort-table="sortTable"
              />
              <exclusion-rows
                :table-rows="sortedTableData"
                :is-loading="isLoading"
                :touched-expressions="touchedExpressions"
                @add-expression="addExpression"
                @remove-expression="removeExpression"
                @update-expression="updateExpression"
              />
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IcBlock from '@/components/icon/brightbid/ic-block.vue'
import TableHeader from '@/components/table/TableHeader.vue'
import ExclusionRows from '@/components/table/table_rows/ExclusionRows.vue'
import { createNewSortInstance } from 'fast-sort'
import Toast from '@/components/shared/Toast.vue'

const defaultSortingOrder = { order: 'ASCENDING', column: null }
const SORTING = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
}

export default {
  name: 'ExclusionList',
  components: { TableHeader, IcBlock, ExclusionRows },
  props: {
    userEmail: {
      type: String,
      required: true,
    },
    siteId: {
      type: Number,
      required: true,
    },
    keywordExclusions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'expression',
          label: 'Expression',
          sortable: true,
          sorting: null,
          position: 'left',
        },
        {
          value: 'mark_as_competitor',
          label: 'Mark as Competitor',
          sortable: false,
          sorting: null,
          position: 'right',
        },
        {
          value: 'action',
          label: 'Action',
          sortable: false,
          sorting: null,
          position: 'right',
        },
      ],
      sortingOrder: defaultSortingOrder,
      sortedTableData: [],
      isLoading: false,
      touchedExpressions: [],
    }
  },
  computed: {},
  watch: {
    keywordExclusions: {
      immediate: true,
      handler(newVal) {
        this.sortedTableData = structuredClone(newVal)
      },
    },
  },
  methods: {
    decideTheSortingOrder(data) {
      // when no sorting order is set
      if (this.sortingOrder === null) return SORTING.ASCENDING

      // when sorting another column
      if (data.value !== this.sortingOrder.column) return SORTING.ASCENDING

      // switch between ascending and descending
      if (data.value === this.sortingOrder.column) {
        return this.sortingOrder.order === SORTING.ASCENDING ? SORTING.DESCENDING : SORTING.ASCENDING
      }
    },
    async sortTable(data) {
      this.$nextTick(() => {
        const order = this.decideTheSortingOrder(data)
        const column = data.value
        this.sortingOrder = { order: order, column: column } // will be sent to child, table rows component must handle the sorting
        this.applySortingOrder(this.sortingOrder)
      })
    },
    applySortingOrder(sortingOrder) {
      const naturalSort = createNewSortInstance({
        comparer: new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' }).compare,
      })

      if (sortingOrder.order === 'ASCENDING') {
        this.sortedTableData = naturalSort(this.sortedTableData).asc(sortingOrder.column)
      }
      if (sortingOrder.order === 'DESCENDING') {
        this.sortedTableData = naturalSort(this.sortedTableData).desc(sortingOrder.column)
      }
    },
    async addExpression(keyword) {
      try {
        this.isLoading = true
        const payload = {
          user_email: this.userEmail,
          expression: keyword,
          is_competitor: false,
        }

        const { data: addedExpression } = await this.$http.post(
          `/search/site/${this.siteId}/keyword-suggestions/kws_exclusions`,
          payload,
        )
        this.$emit('add-expression', addedExpression)
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to add expression. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async removeExpression(id) {
      try {
        this.touchedExpressions.push(id)
        await this.$http.delete(`/search/site/${this.siteId}/keyword-suggestions/kws_exclusions/${id}`)
        this.$emit('remove-expression', id)
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to remove expression. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.touchedExpressions = this.touchedExpressions.filter(item => item !== id)
      }
    },
    async updateExpression(id, isCompetitor) {
      try {
        this.touchedExpressions.push(id)
        const keywordExclusion = this.keywordExclusions.find(item => item.id === id)
        const payload = {
          user_email: this.userEmail,
          expression: keywordExclusion.expression,
          is_competitor: isCompetitor,
        }
        const { data: updatedExpression } = await this.$http.put(
          `/search/site/${this.siteId}/keyword-suggestions/kws_exclusions/${id}`,
          payload,
        )
        this.$emit('update-expression', updatedExpression)
      } catch (e) {
        this.$toast.error({
          component: Toast,
          props: {
            title: 'Error',
            message: 'Failed to update expression. Please try again.',
            type: 'error',
          },
        })
      } finally {
        this.touchedExpressions = this.touchedExpressions.filter(item => item !== id)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
