<template>
  <div class="space-y-3">
    <bbBaseCard
      :id="activity.id"
      :disabled-checkbox="activity.isReverted || !activity.actionTaken"
      :curtain="false"
      :is-checked="isChecked"
      @cardSelect="selectActivity"
    >
      <template #first>
        <!-- FIRST ITEM OF CARDSTATS -->
        <div>
          <div
            v-if="cardStats.length > 0"
            class="flex flex-col"
          >
            <p class="text-bb-text-secondary">{{ cardStats[0].label }}</p>
            <new-tooltip direction="bottom-start">
              <p class="mt-3 font-bold truncate overflow-auto">
                {{ activity[cardStats[0].value] }}
              </p>
              <template #content> {{ activity[cardStats[0].value] }} </template>
            </new-tooltip>
          </div>
        </div>
      </template>
      <template #content>
        <div
          v-for="stat in notButtonCardStats"
          :key="stat.value"
        >
          <div class="card-item">
            <p class="text-bb-text-secondary">{{ stat.label }}</p>
            <div class="flex flex-row gap-2 justify-start p1 max-w-64">
              <div
                v-if="!stat.style"
                class="truncate"
              >
                <div v-if="stat.value === 'adGroup'">
                  <new-tooltip direction="bottom-start">
                    <p class="truncate w-32">
                      {{ activity[stat.value] }}
                    </p>
                    <template #content> {{ activity[stat.value] }} </template>
                  </new-tooltip>
                </div>
                <div
                  v-else
                  class="max-w-64"
                >
                  <!-- First row-->
                  <div>
                    <p
                      v-if="stat.firstRow === 'date'"
                      class="w-64"
                    >
                      {{ activity.isReverted ? activity.datePrefix : '' }}
                      {{ activity[stat.firstRow] }}
                    </p>
                    <p v-if="stat.firstRow === 'user'">
                      <VMenu
                        :disabled="(activity.userPrefix + activity[stat.firstRow]).length < 35"
                        instant-move
                        :distance="8"
                        placement="bottom"
                        theme="sm"
                      >
                        <p class="truncate w-50">
                          {{ activity.isReverted ? activity.userPrefix : '' }}
                          {{ activity.isAutoPilot ? 'Autopilot' : activity[stat.firstRow] }}
                        </p>
                        <template #popper>
                          <div v-close-popper>
                            <div>
                              <p class="text-sm">
                                {{ activity.isReverted ? activity.userPrefix : '' }}
                                {{ activity.isAutoPilot ? 'Autopilot' : activity[stat.firstRow] }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </VMenu>
                    </p>
                    <p v-if="stat.firstRow === 'matchType'">
                      {{ activity[stat.firstRow] }}
                    </p>
                  </div>

                  <!-- Second row-->
                  <div v-if="stat.secondRow">
                    <p v-if="activity[stat.secondRow] && activity.isReverted && stat.secondRow === 'date_reverted'">
                      Reverted on {{ activity[stat.secondRow] }}
                    </p>
                    <p
                      v-if="activity[stat.secondRow] && activity.isReverted && stat.secondRow !== 'date_reverted'"
                      class="truncate w-50"
                    >
                      <VMenu
                        :disabled="`Reverted by ${activity[stat.secondRow]}`.length < 29"
                        instant-move
                        :distance="8"
                        placement="bottom"
                        theme="sm"
                      >
                        <p class="truncate w-50">Reverted by {{ activity[stat.secondRow] }}</p>
                        <template #popper>
                          <div v-close-popper>
                            <div>
                              <p class="text-sm">Reverted by {{ activity[stat.secondRow] }}</p>
                            </div>
                          </div>
                        </template>
                      </VMenu>
                    </p>
                  </div>
                </div>
              </div>
              <!--STATUS-->
              <div v-if="stat.style === 'status'">
                <status :status="activity[stat.value]" />
              </div>

              <!--CHIP TAG-->
              <div
                v-if="stat.style === 'tag'"
                class="ml-2"
              >
                <chip-tag :label="activity.isReverted ? 'reverted' : activity[stat.value].toLowerCase()" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </bbBaseCard>
  </div>
</template>

<script>
import ChipTag from '@/components/shared/ChipTag'
import Status from '@/components/shared/Status'
import bbBaseCard from '@/components/card/bbBaseCard.vue'
import NewTooltip from '@/components/alert/NewTooltip.vue'

export default {
  name: 'ActivityCard',
  components: {
    ChipTag,
    Status,
    bbBaseCard,
    NewTooltip,
  },
  props: {
    cardStats: {
      type: Array,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
    isChecked: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    cardGrid() {
      let total = 0
      if (!this.cardStats.length) return `grid grid-cols-${total}`

      this.cardStats.map(stats => {
        total += stats.colSpan
      })

      return `grid grid-cols-${total}`
    },
    buttonCardStats() {
      return this.cardStats.filter(stat => stat.style === 'button')
    },
    notButtonCardStats() {
      return this.cardStats.filter((stat, statIndex) => stat.style !== 'button' && statIndex !== 0)
    },
  },
  methods: {
    selectActivity() {
      this.$emit('select-activity')
    },
  },
}
</script>

<style scoped>
.card-item {
  @apply text-left flex flex-col;
  min-width: 128px;
}
</style>
