<template>
  <div class="relative bg-white main-container base-scrollbar">
    <div>
      <troubleshoot-banner @show-troubleshoot-modal="showTroubleshootModal = true" />
    </div>

    <div>
      <exclusion-list
        :user-email="context.user.email"
        :site-id="context.site_id"
        :keyword-exclusions="context.keyword_exclusions"
        @add-expression="addExpression"
        @remove-expression="removeExpression"
        @update-expression="updateExpression"
      />
    </div>

    <div>
      <autopilot-settings
        :context="context"
        :is-loading="isLoading"
        @set-page-loading="setPageLoading"
        @refetch-campaigns="$emit('refetch-campaigns')"
      />
    </div>

    <bb-base-modal
      v-if="showTroubleshootModal"
      :width="modalConfig.width"
      :height="modalConfig.height"
      @close="showTroubleshootModal = false"
    >
      <template #container>
        <troubleshoot-business-description
          :offerings="context.offerings"
          :top-keywords="topKeywordsForm"
          :extra-info="context.extra_info"
          :business-description="context.business_description"
          :is-modal-loading="isModalLoading"
          @close="showTroubleshootModal = false"
          @test-on-expressions="testOnExpressions"
          @update-business-description-and-extra-info="updateBusinessDescriptionAndExtraInfo"
        />
      </template>
    </bb-base-modal>
  </div>
</template>

<script>
import AutopilotSettings from '@/views/keywords_suggestions/Settings/AutopilotSettings.vue'
import ExclusionList from '@/views/keywords_suggestions/Settings/ExclusionList.vue'
import TroubleshootBanner from '@/views/keywords_suggestions/Settings/TroubleshootBanner.vue'
import BbBaseModal from '@/components/modals/brightbid/BbBaseModal.vue'
import TroubleshootBusinessDescription from '@/components/modals/forms/TroubleshootBusinessDescription.vue'
import Toast from '@/components/shared/Toast.vue'

export default {
  name: 'CampaignSettings',
  components: { AutopilotSettings, ExclusionList, TroubleshootBanner, BbBaseModal, TroubleshootBusinessDescription },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    context: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalConfig: { width: '600px', height: '800px' },
      showTroubleshootModal: false,
      isModalLoading: false,
      topKeywordsForm: [],
    }
  },
  mounted() {
    const keywords = Object.values(this.context.top_keywords)
    this.topKeywordsForm = keywords.map(keyword => {
      return {
        keyword: keyword,
        relevance: '-',
        intent: '-',
      }
    })
    while (this.topKeywordsForm.length < 5) {
      this.topKeywordsForm.push({
        keyword: '',
        relevance: '-',
        intent: '-',
      })
    }
  },
  methods: {
    setPageLoading(isLoading) {
      this.$emit('set-page-loading', isLoading, 'CAMPAIGN_SETTINGS')
    },
    addExpression(expressionData) {
      this.$emit('add-expression', expressionData)
    },
    removeExpression(id) {
      this.$emit('remove-expression', id)
    },
    updateExpression(expressionData) {
      this.$emit('update-expression', expressionData)
    },
    async testOnExpressions(payload, form) {
      try {
        this.isModalLoading = true
        const { data: results } = await this.$http.post(
          `/search/site/${this.context.site_id}/profile/intent-relevance`,
          payload,
        )
        this.topKeywordsForm = form

        this.topKeywordsForm.map(item => {
          const intentAndRelevance = results[item.keyword]
          if (intentAndRelevance === undefined) {
            item.intent = '-'
            item.relevance = '-'
          } else {
            item.intent = intentAndRelevance[0]
            item.relevance = intentAndRelevance[1]
          }
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.isModalLoading = false
      }
    },
    async updateBusinessDescriptionAndExtraInfo(payload) {
      try {
        this.isModalLoading = true
        await this.$http.patch(`/common/site/${this.context.site_id}/business-extra`, payload)
        this.$emit('update-business-description-and-extra-info-state', payload)

        this.$toast.success({
          component: Toast,
          props: {
            title: 'Success',
            message: `Business description and extra info updated successfully.`,
            type: 'success',
          },
        })
      } catch (e) {
        if (e.response) {
          this.$toast.error({
            component: Toast,
            props: {
              title: 'Error',
              message: `Failed to update business description and extra info. Please try again later.`,
              type: 'error',
            },
          })
        }
      } finally {
        this.isModalLoading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.main-container {
  overflow-y: auto;
  height: calc(100vh - 239px);
}
.banner {
  background: linear-gradient(105.1deg, #f4f2ff 39.38%, #e9e9ff 100.44%);
}
</style>
