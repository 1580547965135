import { assign, createMachine } from 'xstate'

export const STATES = {
  KEYWORD_SUGGESTIONS: 'KEYWORD_SUGGESTIONS',
  ACTIVITY_HISTORY: 'ACTIVITY_HISTORY',
  CAMPAIGN_SETTINGS: 'CAMPAIGN_SETTINGS',
}

export const EVENTS = {
  INITIALIZE: 'INITIALIZE',
  UPDATE: 'UPDATE',
  BACK: 'BACK',
  GO_TO_ACTIVITY_HISTORY: 'GO_TO_ACTIVITY_HISTORY',
  GO_TO_CAMPAIGN_SETTINGS: 'GO_TO_CAMPAIGN_SETTINGS',
  SELECT_CAMPAIGN: 'SELECT_CAMPAIGN',
  ADD_EXPRESSION: 'ADD_EXPRESSION',
  REMOVE_EXPRESSION: 'REMOVE_EXPRESSION',
  UPDATE_EXPRESSION: 'UPDATE_EXPRESSION',
  UPDATE_BUSINESS_DESCRIPTION_AND_EXTRA_INFO: 'UPDATE_BUSINESS_DESCRIPTION_AND_EXTRA_INFO',
}

export const KEYWORD_SUGGESTIONS_STATE_KEY = 'keyword-suggestions-state-key'

// State machine
const keywordSuggestionsMachine = createMachine(
  {
    predictableActionArguments: true,
    id: 'keyword-suggestions',
    initial: STATES.KEYWORD_SUGGESTIONS,
    context: {
      user: null,
      site_id: null,
      organization_id: null,
      business_description: null,
      extra_info: null,
      offerings: [],
      top_keywords: [],
      campaigns: [],
      activity_history: [],
      keyword_exclusions: [],
      keyword_suggestions: [],
      keyword_suggestion_enabled_campaigns: [],
      selected_campaign: null,
    },
    states: {
      [STATES.KEYWORD_SUGGESTIONS]: {
        on: {
          [EVENTS.GO_TO_ACTIVITY_HISTORY]: STATES.ACTIVITY_HISTORY,
          [EVENTS.GO_TO_CAMPAIGN_SETTINGS]: STATES.CAMPAIGN_SETTINGS,
          [EVENTS.UPDATE]: { actions: ['assignActivityHistory'] },
          [EVENTS.SELECT_CAMPAIGN]: { actions: ['assignSelectedCampaign'] },
        },
      },
      [STATES.ACTIVITY_HISTORY]: {
        on: {
          [EVENTS.BACK]: STATES.KEYWORD_SUGGESTIONS,
          [EVENTS.UPDATE]: { actions: ['assignActivityHistory'] },
          [EVENTS.SELECT_CAMPAIGN]: { actions: ['assignSelectedCampaign'] },
        },
      },
      [STATES.CAMPAIGN_SETTINGS]: {
        on: {
          [EVENTS.BACK]: STATES.KEYWORD_SUGGESTIONS,
          [EVENTS.UPDATE]: { actions: ['assignCampaigns'] },
          [EVENTS.ADD_EXPRESSION]: { actions: ['assignExpression'] },
          [EVENTS.REMOVE_EXPRESSION]: { actions: ['removeExpression'] },
          [EVENTS.UPDATE_EXPRESSION]: { actions: ['updateExpression'] },
          [EVENTS.UPDATE_BUSINESS_DESCRIPTION_AND_EXTRA_INFO]: { actions: ['updateBusinessDescriptionAndExtraInfo'] },
        },
      },
    },
  },
  {
    actions: {
      assignActivityHistory: assign((context, event) => {
        if (!event.payload) return []
        return {
          activity_history: event.payload.activity_history,
        }
      }),
      assignCampaigns: assign((context, event) => {
        if (!event.payload) return []
        return {
          campaigns: event.payload.campaigns,
          keyword_suggestion_enabled_campaigns: event.payload.keyword_suggestion_enabled_campaigns,
        }
      }),
      assignSelectedCampaign: assign((context, event) => {
        if (!event.payload) return null
        return {
          selected_campaign: event.payload.selectedCampaign,
        }
      }),
      assignExpression: assign((context, event) => {
        if (!event.payload) return null
        return {
          keyword_exclusions: [...context.keyword_exclusions, event.payload.expression],
        }
      }),
      removeExpression: assign((context, event) => {
        if (!event.payload) return null
        const keyword_exclusions = context.keyword_exclusions.filter(
          expression => expression.id !== event.payload.expression_id,
        )
        return {
          keyword_exclusions: keyword_exclusions,
        }
      }),
      updateExpression: assign((context, event) => {
        if (!event.payload) return null
        const keyword_exclusions = context.keyword_exclusions.map(expression => {
          if (expression.id === event.payload.expression.id) {
            return event.payload.expression
          }
          return expression
        })
        return {
          keyword_exclusions: keyword_exclusions,
        }
      }),
      updateBusinessDescriptionAndExtraInfo: assign((context, event) => {
        if (!event.payload) return null
        return {
          business_description: event.payload.description,
          extra_info: event.payload.extra_info,
        }
      }),
    },
  },
)

export default keywordSuggestionsMachine
