var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tbody',[(_vm.tableRows.length === 0)?_c('tr',[_vm._m(0)]):_vm._l((_vm.tableRows),function(item,index){return _c('tr',{key:item.id,staticClass:"whitespace-no-wrap font-normal w-full text-sm",class:[
      _vm.hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white',
      index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
    ],on:{"mouseenter":function($event){_vm.hoveredRow = index},"mouseleave":function($event){_vm.hoveredRow = null}}},[_c('td',{staticClass:"px-4 py-2 whitespace-no-wrap sticky left-0 w-50",class:[
        _vm.hoveredRow === index ? 'bg-bb-background-purple-0' : 'bg-white',
        index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
      ]},[_vm._v(" "+_vm._s(item.expression)+" ")]),_c('td',{staticClass:"px-4"},[_c('div',{staticClass:"flex items-center justify-end pr-20"},[_c('check-box',{staticClass:"cursor-pointer",class:_vm.touchedExpressions.includes(item.id) ? 'pointer-events-none' : '',attrs:{"input-name":`box-${item.id}`,"input-id":item.id,"value":item.is_competitor},on:{"input":val => {
              _vm.updateExpression(item.id, val)
            }}})],1)]),_c('td',{staticClass:"px-4 text-right"},[_c('div',{staticClass:"flex items-center justify-end pr-5"},[_c('ic-negative',{staticClass:"cursor-pointer",class:_vm.touchedExpressions.includes(item.id)
              ? 'text-bb-disabled-buttons pointer-events-none'
              : 'text-bb-red-error',attrs:{"size":20},on:{"click":function($event){return _vm.removeExpression(item.id)}}})],1)])])}),_c('tr',{staticClass:"bg-neutral-50 h-14 sticky bottom-0"},[_c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"flex justify-between items-center pl-6 pr-2"},[_c('div',{staticClass:"flex items-center gap-6 w-full"},[_c('ic-plus',{staticClass:"text-bb-brand-purple cursor-pointer",attrs:{"size":24},on:{"click":_vm.addExpression}}),(!_vm.isLoading)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.expressionInput),expression:"expressionInput"}],staticClass:"bg-transparent w-full border-0 focus:outline-none",attrs:{"type":"text","placeholder":"Add expression..."},domProps:{"value":(_vm.expressionInput)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.addExpression.apply(null, arguments)},"input":[function($event){if($event.target.composing)return;_vm.expressionInput=$event.target.value},function($event){_vm.hasDuplicateKeyword = false
              _vm.maximumLengthError = false
              _vm.minimumLengthError = false}]}}):_vm._e(),(_vm.isLoading)?_c('ellipsis-loader',{staticClass:"text-bb-brand-purple"}):_vm._e()],1),_c('div',[(!_vm.hasDuplicateKeyword && !_vm.maximumLengthError && !_vm.minimumLengthError)?_c('p',{staticClass:"text-bb-neutral-gray text-xs cursor-default w-32"},[_vm._v(" Press Enter to save ")]):_vm._e(),(_vm.hasDuplicateKeyword)?_c('p',{staticClass:"text-bb-red-error text-xs cursor-default w-48"},[_vm._v(" This expression already exists! ")]):_vm._e(),(_vm.minimumLengthError)?_c('p',{staticClass:"text-bb-red-error text-xs cursor-default w-50"},[_vm._v(" Please enter at least 2 characters. ")]):_vm._e(),(_vm.maximumLengthError)?_c('p',{staticClass:"text-bb-red-error text-xs cursor-default w-50"},[_vm._v(" Please enter at most 22 characters. ")]):_vm._e()])])])])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"4"}},[_c('div',{staticClass:"mx-auto my-20 flex flex-col items-center justify-center"},[_c('p',{staticClass:"text-xl my-2"},[_vm._v("No expressions found...")])])])
}]

export { render, staticRenderFns }