var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{on:{"click":_vm.toogleSwitch}},[_c('div',{staticClass:"require-keywords w-11 py-0.5 h-5 rounded-full cursor-pointer",class:{
      'bg-bb-brand-purple': _vm.status === _vm.SWITCH_STATUS.ENABLED,
      'bg-bb-disabled-buttons': _vm.status === _vm.SWITCH_STATUS.DISABLED,
      'bg-neutral-50': !_vm.toggleable,
    }},[_c('div',{staticClass:"h-4 w-4 rounded-full mx-0.5 transition transform duration-300",class:{
        'bg-white translate-x-6': _vm.status === _vm.SWITCH_STATUS.ENABLED,
        'bg-white translate-x-0': _vm.status === _vm.SWITCH_STATUS.DISABLED,
        'bg-bb-disabled-buttons translate-x-6': _vm.status === _vm.SWITCH_STATUS.ENABLED_BUT_INACTIVE,
        'bg-bb-disabled-buttons translate-x-0': _vm.status === _vm.SWITCH_STATUS.DISABLED_AND_INACTIVE,
      }})])])
}
var staticRenderFns = []

export { render, staticRenderFns }