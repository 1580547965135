<template>
  <tbody>
    <tr v-if="tableRows.length === 0">
      <td colspan="4">
        <div class="mx-auto my-20 flex flex-col items-center justify-center">
          <p class="text-xl my-2">No campaigns found...</p>
        </div>
      </td>
    </tr>
    <tr
      v-for="item in tableRows"
      v-else
      :key="item.campaign_id"
      class="whitespace-no-wrap font-normal w-full text-sm cursor-pointer"
      :class="[
        hoveredRow === item.index || expandedRow === item.campaign_id ? 'bg-bb-background-purple-0' : 'bg-white',
        item.index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
      ]"
      @mouseenter="hoveredRow = item.index"
      @mouseleave="hoveredRow = null"
    >
      <!-- Campaign Rows -->
      <td
        v-if="item.type === 'campaign'"
        class="p-4 whitespace-no-wrap sticky left-0 w-50"
        :class="[
          hoveredRow === item.index || expandedRow === item.campaign_id ? 'bg-bb-background-purple-0' : 'bg-white',
          item.index % 2 === 0 ? 'bg-white' : 'bg-neutral-0',
        ]"
      >
        <VMenu
          instant-move
          :distance="8"
          placement="bottom"
          theme="sm"
        >
          <p class="w-50 truncate">{{ item.campaign_name }}</p>
          <template #popper>
            <div>
              <div>
                <p class="text-sm">{{ item.campaign_name }}</p>
              </div>
            </div>
          </template>
        </VMenu>
      </td>
      <td
        v-if="item.type === 'campaign'"
        class="px-4"
      >
        <div class="flex justify-end items-center h-full">
          <slider-switch
            :id="item.campaign_id"
            type="keyword_suggestion"
            :status="item.keyword_suggestion"
            @update-status="updateStatus"
          />
        </div>
      </td>
      <td
        v-if="item.type === 'campaign'"
        class="px-4 text-right"
      >
        <div class="flex justify-end items-center h-full">
          <VMenu
            :disabled="item.autopilot !== 'DISABLED_AND_INACTIVE'"
            instant-move
            :distance="8"
            placement="bottom"
            theme="sm"
          >
            <slider-switch
              :id="item.campaign_id"
              type="autopilot"
              :status="item.autopilot"
              @update-status="updateStatus"
            />
            <template #popper>
              <div v-close-popper>
                <div>
                  <p class="text-sm">Keyword Suggestions must be enabled before turning Autopilot on</p>
                </div>
              </div>
            </template>
          </VMenu>
        </div>
      </td>
      <td v-if="item.type === 'campaign'">
        <div class="w-10 h-full">
          <ic-chevron
            v-if="item.autopilot === 'ENABLED' || expandedRow === item.campaign_id"
            :direction="expandedRow === item.campaign_id ? 'up' : 'down'"
            class="text-bb-disabled-gray hover:text-bb-text-secondary"
            @click="expandRow(item.campaign_id)"
          ></ic-chevron>
        </div>
      </td>

      <!-- Campaign Details Rows -->
      <td
        v-if="item.type === 'campaign_details'"
        colspan="4"
        class="font-normal bg-white"
      >
        <div class="p-6 w-full space-y-2 text-bb-text-default">
          <p>Enable Autopilot for</p>
          <div class="border rounded-lg overflow-hidden">
            <div class="flex justify-between px-6 py-2">
              <status status="positive">
                <template #status-text>Positive Keywords {{ item.autopilot_enabled.positive }}</template>
              </status>
              <check-box
                v-model="item.autopilot_enabled.positive"
                input-name="positive"
                input-id="positive"
                @input="toggleAutopilotCategory('positive', item.autopilot_enabled.positive)"
              />
            </div>
            <div class="flex justify-between px-6 py-2 bg-neutral-0">
              <status status="negative">
                <template #status-text>Negative Keywords {{ item.autopilot_enabled.negative }}</template>
              </status>
              <check-box
                v-model="item.autopilot_enabled.negative"
                input-name="negative"
                input-id="negative"
                @input="toggleAutopilotCategory('negative', item.autopilot_enabled.negative)"
              />
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<script>
import SliderSwitch from '@/components/input/brightbid/SliderSwitch.vue'
import IcChevron from '@/components/icon/ic-chevron.vue'
import Status from '@/components/shared/Status.vue'
import CheckBox from '@/components/input/brightbid/CheckBox.vue'
import { SWITCH_STATUS } from '@/utils/enums'

export default {
  name: 'AiCampaignRows',
  components: { SliderSwitch, IcChevron, Status, CheckBox },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      hoveredRow: null,
      expandedRow: null,
    }
  },
  computed: {
    tableRows() {
      const output = []
      this.data.map((item, index) => {
        let campaignDetail = {}
        if (this.expandedRow === item.campaign_id) {
          campaignDetail = {
            type: 'campaign_details',
            campaign_id: `${item.campaign_id}_details`,
            campaign_name: item.name,
            autopilot_enabled: item.autopilot_enabled,
          }
        }
        const campaign = {
          index,
          campaign_id: item.campaign_id,
          campaign_name: item.name,
          keyword_suggestion: item.keyword_suggestion ? SWITCH_STATUS.ENABLED : SWITCH_STATUS.DISABLED,
          autopilot: this.computeStatus(item),
          type: item.type,
          autopilot_enabled: item.autopilot_enabled,
        }

        if (campaignDetail.campaign_id) {
          output.push(campaign)
          output.push(campaignDetail)

          return
        }

        output.push(campaign)
      })
      return output
    },
  },
  methods: {
    updateStatus(id, type, status) {
      if (status === 'DISABLED') this.expandedRow = null
      this.$emit('update-status', id, type, status)
    },
    computeStatus(item) {
      if (!item.keyword_suggestion) {
        return item.autopilot ? 'ENABLED_BUT_INACTIVE' : 'DISABLED_AND_INACTIVE'
      }
      if (!item.autopilot_enabled.positive && !item.autopilot_enabled.negative) {
        return 'DISABLED'
      }
      return item.autopilot ? 'ENABLED' : 'DISABLED'
    },
    expandRow(id) {
      if (this.expandedRow === id) this.expandedRow = null
      else this.expandedRow = id
    },
    toggleAutopilotCategory(category, status) {
      this.$emit('toggle-autopilot-category', category, this.expandedRow, status)
    },
  },
}
</script>

<style scoped lang="scss"></style>
