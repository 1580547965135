<template>
  <div class="banner max-w-200 w-full mx-6 mt-6 px-7 py-3 rounded-2xl">
    <div class="flex items-center justify-between">
      <div class="w-115 space-y-2">
        <p class="text-sm font-bold text-bb-brand-purple">Feed the AI</p>
        <p>Enhance suggestion quality by sharing more information about your business.</p>
      </div>
      <img
        class="w-10"
        src="/content/images/brightbid/welcome-bot.svg"
        alt="welcome-robot"
      />

      <div
        class="flex items-center text-center font-medium text-bb-brand-purple cursor-pointer"
        @click="$emit('show-troubleshoot-modal')"
      >
        <p class="w-40">Troubleshoot Business Descritpion</p>
        <ic-chevron direction="right" />
      </div>
    </div>
  </div>
</template>

<script>
import IcChevron from '@/components/icon/ic-chevron.vue'

export default {
  name: 'TroubleshootBanner',
  components: { IcChevron },
}
</script>

<style scoped lang="scss"></style>
